import { LocalShipping } from '@mui/icons-material';
import { Box, ListItemText } from '@mui/material';
import { DataGridPro, GridColDef, gridClasses } from '@mui/x-data-grid-pro';
import { FilingFrequency } from 'api';
import {
  AttributeLabel,
  AutocompleteFieldController,
  CheckboxFieldController,
  DropdownFieldController,
  FullSizePaper,
  InfoAdornment,
  TextFieldController,
} from 'components';
import { CSSLinearProgress } from 'components/CSSLinearProgress';
import { usePageSize } from 'hooks/usePageSize';
import { range } from 'lodash';
import { DateTime } from 'luxon';
import { useAccount } from 'pages/account/hooks/useAccount';
import { ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useAccountingContext } from '../context';

type RowModel = {
  id: string;
  description: { primary: string; secondary?: string | ReactNode };
  setting?: {
    element:
      | typeof DropdownFieldController
      | typeof AutocompleteFieldController
      | typeof TextFieldController
      | typeof CheckboxFieldController;
    props?: Record<string, unknown>;
  };
};

const LocalForm = () => {
  const { gstRate, gstName, showGstHst, t } = useAccount();
  const { entityIds, loading } = useAccountingContext();

  const columns: Array<GridColDef<RowModel>> = [
    {
      field: 'description',
      flex: 2,
      headerName: 'Item',
      sortable: false,
      renderCell({ value }) {
        return <ListItemText {...value} />;
      },
    },
    {
      field: 'setting',
      headerName: 'Setting',
      sortable: false,
      flex: 1,
      renderCell({ value }) {
        const { element: Element, props } = value ?? {};
        return <Box sx={{ my: 2, width: '100%' }}>{Element ? <Element {...props} /> : <></>}</Box>;
      },
    },
  ];

  const rows: RowModel[] = useMemo(
    () => [
      {
        id: 'yearEndMonth',
        description: {
          primary: 'Fiscal Year-end',
        },
        setting: {
          element: DropdownFieldController,
          props: {
            name: 'yearEndMonth',
            size: 'small',
            variant: 'outlined',
            fullWidth: true,
            data: range(1, 13).map((month) => ({
              value: month,
              label: DateTime.fromObject({ month }).monthLong,
            })),
          },
        },
      },
      ...(showGstHst
        ? [
            {
              id: 'receiverGeneralId',
              description: {
                primary: 'Receiver General',
              },
              setting: {
                element: AutocompleteFieldController,
                props: {
                  name: 'receiverGeneralId',
                  variant: 'outlined',
                  fullWidth: true,
                  size: 'small',
                  options: entityIds.supplier,
                  startAdornment: <LocalShipping color="primary" fontSize="small" />,
                },
              },
            },
            {
              id: 'gstFiling',
              description: {
                primary: `${gstName} Filing Frequency`,
              },
              setting: {
                element: DropdownFieldController,
                props: {
                  name: 'gstFiling',
                  fullWidth: true,
                  variant: 'outlined',
                  size: 'small',
                  data: Object.entries(FilingFrequency).map(([label, value]) => ({ label, value })),
                  startAdornment: (
                    <InfoAdornment position="start">
                      Filing frequency is used to calculate GST on management fees and maintenance
                      requests. 'None' will prevent GST from being added.
                    </InfoAdornment>
                  ),
                },
              },
            },
          ]
        : []),
      ...(gstName && gstRate
        ? [
            {
              id: 'gstNumber',
              description: {
                primary: t(`${gstName} Number`),
              },
              setting: {
                element: TextFieldController,
                props: {
                  name: 'gstNumber',
                  fullWidth: true,
                  variant: 'outlined',
                  size: 'small',
                },
              },
            },

            {
              id: 'gstName',
              description: {
                primary: `${t(gstName)} ${gstRate * 100}%`,
                secondary: (
                  <>
                    From <Link to="/account">address</Link>
                  </>
                ),
              },
            },
          ]
        : []),
      {
        id: 'includeDisabledProperties',
        description: {
          primary: 'Include disabled properties',
          secondary: '',
        },
        setting: {
          element: CheckboxFieldController,
          props: {
            name: 'includeDisabledProperties',
            size: 'small',
          },
        },
      },
    ],
    [entityIds.supplier, gstName, gstRate, t]
  );

  const pageConfig = usePageSize({ cacheKey: 'company-information-form', defaultPageSize: 100 });

  return (
    <Box sx={{ height: '100%', width: '100%', mt: 1 }}>
      <DataGridPro
        autoHeight
        disableColumnReorder
        disableRowSelectionOnClick
        disableColumnPinning
        disableColumnMenu
        disableMultipleRowSelection
        isRowSelectable={() => false}
        {...pageConfig}
        getRowHeight={() => 'auto'}
        rows={rows}
        loading={loading}
        columns={columns}
        hideFooter={true}
        columnHeaderHeight={0}
        slots={{
          loadingOverlay: CSSLinearProgress,
        }}
        sx={{
          borderBottom: 'none',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          [`& .${gridClasses.cell}:focus`]: { outline: 'none' },
          [`& .${gridClasses.cell}:focus-within`]: { outline: 'none' },
        }}
      />
    </Box>
  );
};

export default function CompanyInformationForm({
  isStandAlone = false,
}: {
  isStandAlone?: boolean;
}) {
  return isStandAlone ? (
    <Box sx={{ my: 2 }}>
      <LocalForm />
    </Box>
  ) : (
    <FullSizePaper sx={{ p: 1, mb: 2 }}>
      <AttributeLabel>Company Information</AttributeLabel>
      <LocalForm />
    </FullSizePaper>
  );
}
